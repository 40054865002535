// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  filename: 'environment.facilities-83878269-1905b.ts',
  isDevelopment: false,
  isProduction: true,
  isTest: false,

  authenticationConfig: {

    emailLink: false,
    textMessage: true,
    usernamePassword: true
  },

  firebaseConfig: {
    databaseURL: "https://facilities-83878269-1905b-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "facilities-83878269-1905b",
    storageBucket: "facilities-83878269-1905b.appspot.com",

    apiKey: "AIzaSyB_59iwiWuLkwl2pxijwwyuh0kHr5WpxCw",
    authDomain: "facilities-83878269-1905b.firebaseapp.com",
    messagingSenderId: "120247214049",
    appId: "1:120247214049:web:5602aeb343842a7770bca0"
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-facilities-83878269-1905b.cloudfunctions.net',

  productConfig: {

    firebaseAnswersRoot: '/answers-x/facilities',
    // product:  ProductHelper.cloneProduct( new FacilityAccessibilityProduct(), true )
    product:  FacilityAccessibilityProduct4.INSTANCE,
    productType: EProductType.facilities,

  },




};
